/*!
* jQuery Custom-form-elements plugin
* Author: Globalia
* Licensed under the WTFPL license
*/



// Custom Input[type=file]
// --------------------------------------------------

( function( $, window, document ) {

    "use strict";

    var pluginName = "customUpload"

    function Plugin ( element ) {
        this.element = element;
        this._name = pluginName;
        this.init();
    }

    // Avoid Plugin.prototype conflicts
    $.extend( Plugin.prototype, {
        init: function() {
            $(this.element).addClass('input-file-hidden');
            $(this.element).next('label').addBack().wrapAll('<div class="file-upload-wrapper"></div>');
            //IE- il faut trigger un click sur l'input, pour que le component fonctionne au one click au lieu du double click.
        },
        update: function() {
            if($(this.element).val()!=='') {
                $(this.element).next('label').html('<div>' + $(this.element).val().replace("C:\\fakepath\\", "") + '</div>').addClass('is-ready');
            }
        },
        destroy: function() {
            $(this.element).unwrap();
        },
        callFunction: function (call, options) {
            if(typeof this[call] === 'function') {
                return this[call](options);
            }
        }
    } );

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[ pluginName ] = function(call, options ) {
        return this.each( function() {
            if ( !$.data( this, "plugin_" + pluginName ) ) {
                $.data( this, "plugin_" + pluginName, new Plugin( this, options || call ) );
            } else {
                return $.data(this, "plugin_" + pluginName).callFunction(call, options);
            }
        } );
    };

    $(function () {
        $(document).find('[gr-form] input[type=file]')
        .customUpload()
        .on('change', function () {
            $(this).customUpload('update');
        });
    });

} )( require('jquery'), window, document );
