window.jquery = window.jQuery = window.$ = require('jquery');
require('sass-to-js');

( function( $ ) {

    "use strict"; // Start of use strict

    if(!$('[gr-handler]').length) {
        $('body').attr('gr-handler','');
    }

    window.sassBreakpoints = $('[gr-handler]').sassToJs({
        pseudoEl: '::before',
        cssProperty: 'content',
        debug: true
    });

    window.scrollbarWidth = function() {
        var $inner = $('<div style="width: 100%; height:1px;"></div>'),
            $outer = $('<div style="width:1px;height:1px; position: absolute; top: 0; left: 0; visibility: hidden; overflow:hidden;"></div>').append($inner),
            inner = $inner[0],
            outer = $outer[0];
        $('body').append(outer);
        var width1 = inner.offsetWidth;
        $outer.css('overflow', 'scroll');
        var width2 = outer.clientWidth;
        $outer.remove();
        return (width1 - width2);
    }

} )( jQuery );

window.debounce = function(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};
