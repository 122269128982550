// ==========================================================================
// g.Low
// Author: Globalia
// Licensed under the WTFPL license
// ==========================================================================


// groots core !! DO NOT DELETE !!
// --------------------------------------------------

require('../../ground/seeds/gr-core.js');

// groots buds .. Uncomment as needed
// --------------------------------------------------

if (typeof $ === 'undefined') window.$ = require('jquery');

// Utilities
if (window.emulateObjectfit === undefined) require('../../ground/buds/objectfit-polyfill/gr-objectfit.js');
if ($.fn.inliner === undefined) require('../../ground/buds/svg-inliner/gr-inline-svg.js');
if ($.fn.equalizer === undefined) require('../../ground/buds/equalizer/gr-equalizer.js');
if ($.fn.lazyloader === undefined) require('../../ground/buds/lazyloader/gr-lazy-loader.js');
//require('../../ground/buds/gr-snippets.js');

// Plugins
if ($.fn.navigation === undefined) require('../../ground/buds/navigation/gr-navigation.js');
if ($.fn.customSelect === undefined) require('../../ground/buds/form/gr-form-select.js');
if ($.fn.customUpload === undefined) require('../../ground/buds/form/gr-form-file.js');
if ($.fn.customRadio === undefined) require('../../ground/buds/form/gr-form-radio.js');
if ($.fn.customCheckbox === undefined) require('../../ground/buds/form/gr-form-checkbox.js');
require('../../ground/buds/form/gr-form-parsley.js');
if ($.fn.expander === undefined) require('../../ground/buds/expander/gr-expander.js');
if ($.fn.accTabs === undefined) require('../../ground/buds/accordion-tabs/gr-accordion-tabs.js');
if ($.fn.modal === undefined) require('../../ground/buds/modal/gr-modal.js');
if ($.fn.videoBg === undefined) require('../../ground/buds/video-background/gr-video-bg.js');

require('magnific-popup');

window.styleArray = [
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#d3d3d3"
            }
        ]
    }, {
        "featureType": "transit",
        "stylers": [
            {
                "color": "#808080"
            }, {
                "visibility": "off"
            }
        ]
    }, {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"
            }, {
                "color": "#b3b3b3"
            }
        ]
    }, {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    }, {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            }, {
                "color": "#ffffff"
            }, {
                "weight": 1.8
            }
        ]
    }, {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#d7d7d7"
            }
        ]
    }, {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            }, {
                "color": "#ebebeb"
            }
        ]
    }, {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#a7a7a7"
            }
        ]
    }, {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    }, {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    }, {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            }, {
                "color": "#efefef"
            }
        ]
    }, {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#696969"
            }
        ]
    }, {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            }, {
                "color": "#737373"
            }
        ]
    }, {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }, {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }, {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#d6d6d6"
            }
        ]
    }, {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }, {}, {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#dadada"
            }
        ]
    }
];

if ($.fn.googlemaper === undefined) require('../../ground/buds/google-map/gr-googlemap.js');

( function( $ ) {
    "use strict";

    var $grnav = $(document).find('[gr-nav]');

    $(window).on('scroll load', function() {
        if ($(window).scrollTop() > 10) {
            $grnav.addClass('is-scrolled');
        } else {
            $grnav.removeClass('is-scrolled');
        }
    });

    // submenu toggle
    $('.has-sub > a').on('click', function(event) {
        event.preventDefault();
        $(this).parent().toggleClass('is-open');
        $(this).next('.nav__sub').toggleClass('is-open');
    });

    $(document).on('click', function(event) {
        if (!$('.has-sub').is(event.target) && $('.has-sub').has(event.target).length === 0) {
            $('.nav__sub').removeClass('is-open');
            $('.has-sub').removeClass('is-open');
        }
    });

    $('.immeuble-filtre select').on('change', function() {
        var $form = $(this).closest('form');
        if ($(this).hasClass('categorie')) {
            window.location.href = $form.data('base-url') + $(this).val() + '/';
        } else {
            $form.submit();
        }
    });

    $('[gr-scroll-to]').click(function(event) {
        event.preventDefault();
        var element = $(this).attr('gr-scroll-to');
        $("html, body").animate({ scrollTop: $(element).offset().top + 'px' }, 600 );
    });


    $('.popup-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        }
    });

    $('.popup-gallery-link').on('click', function () {
        $(this).find('.popup-gallery').magnificPopup('open');
    });

    require('../../../node_modules/parsleyjs/dist/i18n/fr.js');

    if ($("html").attr('lang') === 'fr') {
        window.Parsley.setLocale('fr');
    } else {
        window.Parsley.setLocale('en');
    }

    $(document).on('click', '[data-js-popup]', function(event) {
        event.preventDefault();

        var winHeight = 400,
            winWidth = 600,
            winTop = ($(window).height() / 2) - (winHeight / 2),
            winLeft = ($(window).width() / 2) - (winWidth / 2),
            url = $(this).attr('href');

        window.open(url, '_blank', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
    });

}( jQuery ));
