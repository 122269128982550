/*!
 * jQuery Accordion / Tabs plugin
 * Author: Globalia
 * Licensed under the WTFPL license
 */

( function( $, window, document ) {

    "use strict";

    var pluginName = "accTabs",
        defaults = {
            breakpoint: 0,
            collapse: false
        };

    function Plugin ( element, options ) {
        this.name = pluginName;
        this.element = element;
        this.settings = $.extend( {}, defaults, options );
        this.init();
    }

    $.extend( Plugin.prototype, {
        init: function() {
            var instance = this;
            for( var i in defaults ){
                instance.settings[i] = $(instance.element).attr('gr-acctab-'+i) || instance.settings[i];
            }
            instance.breakpoint = ( isNaN(instance.settings.breakpoint) ? parseInt(window.sassBreakpoints[instance.settings.breakpoint], 10) : this.settings.breakpoint);

            $(instance.element).on('click', '.tab > a', function(event) {
                event.preventDefault();
                if (!$(this).hasClass('is-active')) {
                    $(instance.element).find('.is-open').removeClass('is-open');
                    $(this).next().addClass('is-open');
                    $(instance.element).find('.is-active').removeClass('is-active');
                    $(this).addClass('is-active');
                    $("html, body").animate({ scrollTop: $(this).offset().top + 'px' }, 600 );
                } else if(instance.settings.collapse){
                    $(instance.element).find('.is-open').removeClass('is-open');
                    $(instance.element).find('.is-active').removeClass('is-active');
                }
            });
        },
        callFunction: function (call, options) {
            if(typeof this[call] === 'function') {
                return this[call](options);
            }
        }
    });

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[ pluginName ] = function(call, options ) {
        return this.each( function() {
            if ( !$.data( this, "plugin_" + pluginName ) ) {
                $.data( this, "plugin_" + pluginName, new Plugin( this, options || call ) );
            } else {
                return $.data(this, "plugin_" + pluginName).callFunction(call, options);
            }
        });
    };

    $(function () {
        $(document).find('[gr-acctab]').accTabs();
    });

}) (require('jquery'), window, document );
