/*!
* jQuery InlinerSVG plugin
* Author: Globalia
* Licensed under the WTFPL license
*
* Replace all SVG images with the class .svg in inline SVG
*/



( function( $, window, document ) {

    "use strict";

    var pluginName = "inliner";

    function Plugin ( element ) {
        this.element = element;
        this._name = pluginName;
        this.id = $(this.element).prop('id');
        this.class = $(this.element).prop('class');
        this.src = $(this.element).prop('src');
        this.init();
    }

    $.extend( Plugin.prototype, {
        init: function() {

            var image = this.element,
                $image = $( image ),
                $svg;

            $.get(this.src, function (data) {
                // Get the SVG tag, ignore the rest
                $svg = $(data).find('svg');
                // Add replaced image's classes to the new SVG
                if ($image.attr("class") !== undefined) {
                    $svg.attr('class', $image.attr("class") + ' replaced-svg');
                    $svg.removeClass('svg');
                }
                //Transert all attributes to the new SVG
                $.each(image.attributes, function() {
                    if(this.specified && this.name != "class") {
                        $svg.attr(this.name, this.value);
                    }
                });

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');
                // Replace image with new SVG
                $image.replaceWith($svg);
            }, 'xml');
        }
    } );

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[ pluginName ] = function(call, options ) {
        return this.each( function() {
            if ( !$.data( this, "plugin_" + pluginName ) ) {
                $.data( this, "plugin_" + pluginName, new Plugin( this, options || call ) );
            } else {
                return $.data(this, "plugin_" + pluginName).callFunction(call, options);
            }
        } );
    };

    $(function () {
        $(document).find('img.svg').inliner();
    });

}(require('jquery'), window, document));
