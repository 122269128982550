/*!
 * jQuery Custom-form-elements plugin
 * Author: Globalia
 * Licensed under the WTFPL license
 */



// Parsley validation
// --------------------------------------------------

( function( $ ) {

    "use strict";

    $("[gr-form]").length && $("[gr-form]").parsley({
        trigger:      'change',
        errorClass: "parsley-error",
        classHandler: function (el) {
            return el.$element.closest('.form-item, .form-item--inline, .form-item--icon-after, .form-item--icon-before');
        },
        errorsContainer: function(pEle) {
            var $err = pEle.$element.closest('.form-item, .form-item--inline, .form-item--icon-after, .form-item--icon-before').append();
            return $err;
        }
    });

} )( require('jquery'), require('../../../../node_modules/parsleyjs/dist/parsley.min.js') );
