/*!
 * jQuery Custom-form-elements plugin
 * Author: Globalia
 * Licensed under the WTFPL license
 */



// Custom Radio
// --------------------------------------------------

( function( $, window, document ) {

    "use strict";

    var pluginName = "customRadio";

    function Plugin ( element ) {
        this.element = element;
        this._name = pluginName;
        this.init();
    }

    // Avoid Plugin.prototype conflicts
    $.extend( Plugin.prototype, {
        init: function() {
            $(this.element).addClass('radio-hidden');
            $(this.element).next('label').addBack().wrapAll('<div class="radio-wrapper"></div>');
        },
        destroy: function() {
            $(this.element).unwrap();
        }
    } );

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[ pluginName ] = function( options ) {
        return this.each( function() {
            if ( !$.data( this, "plugin_" + pluginName ) ) {
                $.data( this, "plugin_" + pluginName, new Plugin( this, options ) );
            }
        } );
    };

    $(function () {
        $(document).find('[gr-form] input[type=radio]').customRadio();
    });

} )( require('jquery'), window, document );
